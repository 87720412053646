// stylelint-disable no-invalid-position-at-import-rule
@use "../../../common/variables" as *;
@use "../../../common/utils";

@import "./pupil-progress-filters";
@import "./pupil-progress-table";

.pupil-progress__page-title.page-title {
  margin-bottom: 0;
}

.pupil-table__row--image {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: $margin-8;
  border: 1px solid $c-border;
  border-radius: 100%;
}
