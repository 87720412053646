// stylelint-disable declaration-block-no-redundant-longhand-properties
@use "../../../common/variables" as *;
$word-tower-cell-size:  5.875rem;

.show-word-tower__instruction {
  font-size: $fs-xlarge;
}

.word-tower-display__row {
  display: grid;
  grid-template-rows: $word-tower-cell-size;
  grid-template-columns: repeat(auto-fit, $word-tower-cell-size);
}

.word-tower-display__group {
  $border-style: $border-dark;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 $margin-32;
  transition: background-color 0.3s ease;
  border: $border-style;
  border-bottom: none;
  border-left: none;
  font-size: $fs-xxlarge;
  font-weight: bold;
  grid-column: span var(--span, 1);

  .word-tower-display__part {
    visibility: hidden;
    transition: visibility 0s ease 0.3s, opacity 0.3s ease;
    opacity: 0;
    font-family: $ITCStoneFont;
  }

  .word-tower-display__row--active & {
    background-color: $c-yellow-extra-light;

    .word-tower-display__part {
      visibility: visible;
      transition: visibility 0s ease 0s, opacity 0.3s ease;
      opacity: 1;
    }
  }

  &:first-child {
    border-left: $border-style;
  }

  .word-tower-display__row:last-child & {
    border-bottom: $border-style;
  }
}

.word-tower-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  border: $border-dark;
  border-radius: $border-radius-large;
  gap: $margin-28;

  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 1rem;
    background: $brand-colors-image;
  }
}

.word-tower-summary__image {
  width: 60%;
  height: 60%;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
}

.word-tower-summary__word {
  margin-bottom: $margin-24;
  font-size: $fs-xxlarge;
  font-weight: bold;
  text-align: center;
}

.show-word-tower {
  display: grid;
  grid-template-areas:
    "instruction instruction"
    "word-tower summary"
    "word-tower next-button";
  grid-template-rows: auto auto auto;
  grid-template-columns: auto clamp(16rem, 34vw, 22.625rem);
  gap: $margin-32;

  .word-tower-display {
    grid-area: word-tower;
  }

  .word-tower-summary {
    grid-area: summary;
  }

  .show-word-tower__instruction {
    grid-area: instruction;
  }

  .show-word-tower__next-button {
    grid-area: next-button;
    margin: auto 0 0 auto;
  }

  @media (max-width: 800px) {
    grid-template-areas:
      "instruction instruction"
      "word-tower word-tower"
      "summary next-button";
    grid-template-columns: auto 1rem;
  }
}
