// stylelint-disable declaration-block-no-redundant-longhand-properties
@use "../../../common/variables" as *;

.show-video {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  .video-display {
    display: flex;
    position: relative;
    flex-grow: 1;
    justify-content: center;
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;
    padding-block-end: 2rem;

    & > div {
      /* stylelint-disable declaration-no-important */
      // No choice but to use important due to the video player module applying inline styles
      width: 100% !important;
      height: auto !important;
      min-height: 100% !important;
      /* stylelint-enable */
    }
  }
}

.show-video__instruction {
  margin-bottom: 1.5rem;
  font-size: $fs-xlarge;
}

.show-video__next-button {
  width: fit-content;
  margin: 0 auto;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  &.show-video__next-button--show {
    opacity: 1;
  }
}
