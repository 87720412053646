@use "../../../common/utils";
@use "../../../common/variables" as *;

.pupil-progress__current-lesson {
  position: relative;

  .icon {
    @include utils.icon-size(small);

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      transition: color 0.5s ease;
      color: $c-green;
      cursor: pointer;
    }
  }
}
