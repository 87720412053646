.image-input .file-input__buttons {
  position: relative;
}

.image-input__preview {
  position: absolute;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: center;
}
