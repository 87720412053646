@use "../../../../../common/variables" as *;

.rich-text-editor {
  .tiptap {
    max-width: 75ch;
    margin-top: 0.25rem;
    padding: 0.25rem;
    border: $border;
    border-radius: $border-radius;
  }

  blockquote {
    padding-left: 1rem;
    border-left: $border;
    border-width: 0.125rem;
  }
}
