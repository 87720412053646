@use "../common/variables" as *;

$tile-size: 5.125rem;
$wrong-pw-animation-duration: 0.4s;

.password-display {
  margin-bottom: $margin-80;
}

.password-display__label {
  margin-bottom: $margin-32;
  font-size: $fs-large;
  text-align: center;
}

.password-display__value,
.password-input {
  display: grid;
  gap: $margin-32;
  grid-template-columns: repeat(4, $tile-size);
  justify-content: center;
}

.password-input__item-button {
  margin: 0;
  padding: 0;
  transition: transform 0.2s ease;
  border: 0;
  background-color: transparent;
}

.password-display__value-part,
.password-input__item-button {
  width: $tile-size;
  overflow: hidden;
  border: 0.0625rem solid $c-gray;
  border-radius: $border-radius;
  aspect-ratio: 1/1;
}

.password-input__item-button:hover:not(:disabled),
.password-input__item-button:focus:not(:disabled) {
  transform: scale(1.05);
}

.password-display__image,
.password-input__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  pointer-events: none;

  &[src=""] {
    display: none;
  }
}

.password-display--invalid .password-display__image {
  opacity: 0;
}

.password-display--invalid .password-display__value {
  animation-name: horizontal-shake;
  animation-duration: $wrong-pw-animation-duration;
}

.password-display__value-part {
  .password-display__image {
    transition: opacity 0.3s $wrong-pw-animation-duration;
  }

  &:nth-child(3) .password-display__image {
    transition: opacity 0.3s ($wrong-pw-animation-duration + 0.1s);
  }

  &:nth-child(2) .password-display__image {
    transition: opacity 0.3s ($wrong-pw-animation-duration + 0.2s);
  }

  &:nth-child(1) .password-display__image {
    transition: opacity 0.3s ($wrong-pw-animation-duration + 0.3s);
  }
}

.password-display--authorizing .password-display__value-part {
  // Make users feel like something is happening
  animation-name: up-down;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;

  &:nth-child(1) { animation-delay: 0s; }
  &:nth-child(2) { animation-delay: 0.3s; }
  &:nth-child(3) { animation-delay: 0.6s; }
  &:nth-child(4) { animation-delay: 0.9s; }
}

.auth-error {
  max-width: 26.5rem;
  margin-right: auto;
  margin-left: auto;
}

@keyframes horizontal-shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-2rem); }
  40% { transform: translateX(2rem); }
  60% { transform: translateX(-1rem); }
  80% { transform: translateX(1rem); }
  100% { transform: translateX(0); }
}

@keyframes up-down {
  0% { transform: translateY(0); }
  50% { transform: translateY(0.5rem); }
  100% { transform: translateY(0); }
}
