@use "../variables" as *;

.user-menu__user {
  display: flex;
  align-items: center;

  .dropdown-toggle {
    padding-left: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.user-menu__icon {
  margin-right: $margin-tiny;
  color: $c-blue;
}

.user-menu__user-name {
  display: block;
  color: $c-black;
}

.user-menu__user-company {
  display: block;
  color: $c-black;
  font-size: $fs-small;
}

.btn .user-menu__toggle-icon.icon {
  margin-right: calc(-0.9375rem - 0.5em);
  margin-left: 0.5em;
  font-size: 0.8em;

  svg {
    transition: transform 0.2s ease;
  }

  .dropdown.show & svg {
    transform: rotate(180deg);
  }
}

.user-menu__user .dropdown-menu {
  padding-right: $margin-16;
  padding-left: $margin-16;
  border: none;
  box-shadow: $drop-shadow;

  .dropdown-item {
    padding-left: 0;

    &:hover {
      background: inherit;
      color: $c-green;
    }

    /* stylelint-disable no-descending-specificity */
    .user-menu__icon svg {
      width: 1.3em;
    }
  }
}
