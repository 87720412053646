@use "../../../common/variables" as *;

.pupil-progress__filters {
  display: grid;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1rem;
  grid-template-columns: 1fr 40rem;

  .pupil-progress__filters--dropdowns {
    display: flex;
    width: fit-content;
  }

  .input__search--user {
    margin-top: -0.5rem;
  }

  .dropdown,
  .separator {
    padding: 0 $margin-8;

    &:first-child {
      padding-left: 0;
    }
  }
}

.pupil-progress__group-dropdown.dropdown-toggle {
  padding: 0;
  border: none;
  background: transparent;
  color: $c-black;

  &:hover {
    color: $c-black;
  }

  &:focus {
    border: none;
    background: transparent;
    box-shadow: none;
    color: $c-black;
  }
}

.show > .pupil-progress__group-dropdown.dropdown-toggle {
  @extend .pupil-progress__group-dropdown;

  &:focus {
    border: none;
    background: transparent;
    box-shadow: none;
  }

  .icon svg {
    transform: rotate(180deg);
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $c-green;
}
