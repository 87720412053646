.modal-info__text {
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-size: 1.125rem;
  font-weight: bold;
  gap: 10px;
}

.modal-info__rules {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 30px;
}

.modal-info__rule {
  position: relative;
}

.modal-info__rule-1 img {
  width: 302px;
}

.modal-info__rule-2 img {
  width: 220px;
}

.modal-info__rule-1-arrow-1,
.modal-info__rule-1-arrow-2,
.modal-info__rule-1-arrow-3 {
  .modal-arrow__arrow {
    width: 130px;
  }
}

.modal-info__rule-1-arrow-1 {
  top: 16%;
  right: 0;
}

.modal-info__rule-1-arrow-2 {
  top: 49%;
  right: 0;
}

.modal-info__rule-1-arrow-3 {
  top: 82%;
  right: 0;
}

.modal-info__rule-2-arrow-1 {
  top: 37%;
  right: 0;

  .modal-arrow__arrow {
    width: 200px;
  }
}

.modal-info__rule-2-arrow-2 {
  top: 75%;
  right: 0;

  .modal-arrow__arrow {
    width: 262px;
    height: 8px;
  }
}

.modal-info__rule-2-arrow-3 {
  top: 95%;
  right: 0;

  .modal-arrow__arrow {
    width: 350px;
    height: 20px;
  }
}
