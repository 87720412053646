@use "../common/variables" as *;

.school-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7rem;

  h2 {
    margin-top: $margin-24;
    margin-bottom: $margin-16;
    font-size: $fs-larger;
  }

  .page-title {
    margin-bottom: $margin-24;
  }

  .page-title__title {
    margin: 0;
    font-size: $fs-xlarge;
  }

  .school-home__article {
    flex: 30rem 1 1;
    max-width: 47rem;
  }

  .school-home__school-name {
    font-size: $fs-xlarge;
  }

  .school-home__school-code {
    margin-bottom: $margin-8;
  }

  .school-home__contact-info .icon {
    margin-right: $margin-8;
  }

  .school-home__app-example {
    display: block;
    width: 16rem;
    margin-bottom: $margin-8;
  }

  .school-home__open-app,
  .school-home__switch-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
