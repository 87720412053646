.modal-body,
.modal-header,
.modal-footer {
  padding: 0;
}

.modal-body {
  padding-bottom: $margin-24;
}

.modal-content {
  padding: 2.5rem;
  border-radius: 0.6rem;
}

.modal-header {
  margin-bottom: $margin-24;
  padding-bottom: $margin-24;

  .modal-title {
    font-size: 1.25rem;
  }
}

.modal-footer {
  .danger-button {
    margin-top: 1rem;
  }

  .btn {
    margin-top: $margin-24;
  }
}
