@use "../../../common/variables" as *;

.reading-comprehension-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 2rem;
  flex-grow: 1;
}

.reading-comprehension-layout__buttons {
  display: flex;
  gap: 1rem;
  align-self: flex-end;
  justify-self: flex-end;
  grid-row: 2;
}
