.lesson-document-editor__recovery-input {
  font-family: "Courier New", monospace;
}

.lesson-document-editor__add-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 1rem;
}
