/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 * 
 * 
 * 
 * © 2020 MyFonts Incn*/



  
@font-face {
  font-family: "ITCStoneInformalSemiBoldItalic";
  src: url('webFonts/ITCStoneInformalSemiBoldItalic/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalSemiBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "ITCStoneInformalMedium";
  src: url('webFonts/ITCStoneInformalMedium/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalMedium/font.woff') format('woff');
}
@font-face {
  font-family: "ITCStoneInformalBoldItalic";
  src: url('webFonts/ITCStoneInformalBoldItalic/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "ITCStoneInformalMediumItalic";
  src: url('webFonts/ITCStoneInformalMediumItalic/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: "ITCStoneInformalBold";
  src: url('webFonts/ITCStoneInformalBold/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalBold/font.woff') format('woff');
}
@font-face {
  font-family: "ITCStoneInformalSemiBold";
  src: url('webFonts/ITCStoneInformalSemiBold/font.woff2') format('woff2'), url('webFonts/ITCStoneInformalSemiBold/font.woff') format('woff');
}

