// stylelint-disable no-invalid-position-at-import-rule
@use "./common/variables" as *;

$theme-colors: (
  "primary":    $c-blue,
  "secondary":  $c-blue,
  "success":    $c-green,
  "info":       $c-blue,
  "warning":    $c-yellow,
  "danger":     $c-red,
  "light":      $c-blue,
  "dark":       $c-black,
);

@import "~bootstrap/scss/bootstrap";
@import "./common/components/index";
@import "./school-module/index";
@import "./fonts/ITC_Stone_informal/ITC_Stone_informal.css";

@font-face {
  font-family: Asap;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 100 900;
  src: url("./fonts/asap.ttf") format("truetype");
}

@font-face {
  font-family: Asap;
  font-stretch: 75% 125%;
  font-style: italic;
  font-weight: 100 900;
  src: url("./fonts/asap-italic.ttf") format("truetype");
}

body {
  margin: 0;
  color: $c-black;
  font-family: Asap, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h2 {
  font-size: $fs-h2;
}

h3 {
  margin-bottom: $margin-20;
  font-size: $fs-h3;
}

h4,
h5,
h6 {
  font-size: $fs-h4;
}

button {
  color: $c-black; // Because Safari on iOS makes button label blue
}

.form-label {
  font-size: $fs-label;
  font-weight: normal;
}

.form-control,
.form-select {
  margin-bottom: $margin-20;
  padding: 0.69rem 1.25rem;
  border-radius: $border-radius;
  border-color: $c-border;
  background-position: right $margin-20 center;
  background-size: 1rem 1rem;

  &.is-invalid {
    margin-bottom: 0;
  }
}

.invalid-feedback {
  margin-bottom: $margin-20;
}

.row {
  --bs-gutter-x: #{$margin-20};
}

.btn {
  padding: 0.69rem 1.25rem;
  color: $c-white;

  &:hover,
  &:focus {
    color: $c-white;
  }

  &.outer-shadow {
    box-shadow: $outer-shadow;
  }

  &.bigger-radius {
    border-radius: $border-radius;
  }

  .icon {
    margin-right: 0.5em;
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  border-color: $c-gray;
  background-color: $c-gray;
  box-shadow: none;
  color: $c-white;
}

.alert {
  border-radius: $border-radius-small;
}

.alert-success {
  border-color: map-get($status-colors, "finished");
  background-color: $c-green-pale;
  color: $c-black;
}

.modal:not(.big-modal) {
  .modal-dialog {
    .modal-content {
      padding: $margin-20 $margin-24;
    }

    .modal-header {
      margin: (-$margin-20) (-$margin-24) $margin-20;
      padding: $margin-20 $margin-24;
      box-shadow: $drop-shadow;
      font-size: $fs-larger;
      font-weight: bold;
    }

    .btn-close {
      width: 1rem;
      height: 1rem;
      opacity: 1;
      background-size: 1rem;
    }
  }
}
