.not-found__page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.not-found__button {
  margin-top: 5rem;
}

.not-found__page .hint-balloon__image {
  width: 25rem;
  height: 20rem;
}
