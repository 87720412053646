@use "../variables" as *;

.danger-button {
  padding: 0;
  transition: color 0.3s ease;
  border: 0;
  background-color: transparent;
  color: $c-red;
  text-align: center;

  &:hover,
  &:focus-visible {
    color: $c-red-dark;
  }
}

.icon + .danger-button__label {
  margin-left: $margin-16;
}
