@use "../common/variables" as *;

$tile-image-size: 8.8rem;

.select-lesson__lessons {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-auto-rows: 15.6875rem;
  gap: $margin-24;
}

.select-lesson__lesson-link {
  display: block;
  position: relative;
  height: 100%;
  padding: $margin-40 ($tile-image-size + 0.5rem) $margin-40 $margin-40;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: $border-radius-large;
  background-color: $c-background;
  box-shadow: $drop-shadow;
  color: $c-black;
  font-size: $fs-xxlarge;
  text-decoration: none;
  overflow-wrap: break-word;

  &:hover,
  &:active {
    transform: scale(1.01);
    box-shadow: $outer-shadow;
    color: $c-black;
  }

  &.select-lesson__lesson-link--disabled {
    opacity: 0.5;
    filter: grayscale(50%);

    &:hover,
    &:active {
      transform: none;
      box-shadow: $drop-shadow;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: $tile-image-size;
    height: calc(100% - #{$margin-40 * 2});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .select-lesson__lesson--decoding & {
    background-color: $c-blue-light;

    &::before {
      background-image: url("./tile-image-decoding.png");
    }
  }

  .select-lesson__lesson--reading-comprehension & {
    background-color: $c-blue-light;

    &::before {
      background-image: url("./tile-image-reading-comprehension.svg");
    }
  }

  .select-lesson__lesson--bookshelf & {
    background-color: $c-green-pale;

    &::before {
      background-image: url("./tile-image-bookshelf.png");
    }
  }

  .select-lesson__lesson--vocabulary & {
    background-color: $c-green-pale;

    &::before {
      background-image: url("./icon-chest.png");
    }
  }

  .select-lesson__lesson--strong-reader & {
    background-color: $c-yellow-extra-light;

    &::before {
      background-image: url("./strong-reader.png");
    }
  }
}
