@use "../../../common/variables" as *;

.reading-comprehension-finished {
  text-align: center;

  h2 {
    margin-bottom: $margin-24;
    font-size: $fs-xlarge;
  }

  img {
    width: 19.0625rem;
  }
}
