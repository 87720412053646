@use "../variables" as *;
@use "../utils";
@use "sass:math";

.info {
  display: inline;
  position: relative;
  font-size: inherit;
}

.info__label {
  @include utils.visually-hidden;
}

.info__icon {
  display: inline-block;
  color: $c-blue;
  font-size: 0.75em;
}

.info__content {
  $border-size: 0.0625rem;
  $arrow-size: 1rem;
  $arrow-position-from-bottom: $border-size + math.div($arrow-size, 2);

  visibility: hidden;
  position: absolute;
  bottom: calc(100% + #{$arrow-position-from-bottom + 0.1rem});
  left: -1em;
  width: 19.5rem;
  padding: $margin-12;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  border: solid $border-size $c-blue;
  border-radius: $border-radius-small;
  opacity: 0;
  background-color: $c-white;
  color: $c-blue;
  font-size: $fs-default;
  font-weight: normal;

  &::before {
    $arrow-size: 1rem;

    content: "";
    display: block;
    position: absolute;
    bottom: -$arrow-position-from-bottom;
    left: 1.1em;
    width: $arrow-size;
    height: $arrow-size;
    transform: rotate(-45deg);
    border-bottom: inherit;
    border-left: inherit;
    background-color: inherit;
  }
}

.info:hover,
.info:focus-visible {
  .info__content {
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s;
    opacity: 1;
  }
}
