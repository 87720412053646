@use "./variables" as *;

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

@mixin icon-size($icon-size) {
  $icon-sizes: (
    "smaller": 0.5rem,
    "small": 0.75rem,
    "normal": 1rem,
    "big": 1.25rem,
    "bigger": 1.5rem,
  );

  font-size: map-get($icon-sizes, $icon-size);
}

@mixin status-color-modifiers($attribute: "color", $prefix: "--") {
  @each $name, $color in $status-colors {
    &#{$prefix}#{$name} {
      #{$attribute}: $color;
    }
  }
}

@mixin pupil-font {
  font-family: $ITCStoneFont;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $ITCStoneFontSemiBold;
  }
}
