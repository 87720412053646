.modal-goal__text {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
  font-size: 1.125rem;
  font-weight: bold;
}

.modal-goal__tip-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.81rem;
  font-weight: 600;
  gap: 10px;
}

.modal-goal__tip {
  margin-top: 30px;
}

.modal-goal__tip-transcription {
  position: relative;
  width: 305px;
}

.modal-goal__arrow {
  top: 50%;
  left: calc(100% + 20px);
  transform: translateY(-50%);
}

.modal-goal__arrow-1 .modal-arrow__arrow {
  width: 285px;
}

.modal-goal__arrow-2 .modal-arrow__arrow {
  width: 120px;
}

.modal-goal__arrow-3 .modal-arrow__arrow {
  width: 105px;
}
