@use "../common/variables" as *;

@mixin list {
  display: grid;
  grid-template-columns: repeat(6, 7rem);
  margin: 0;
  padding: 0;
  list-style: none;
  gap: $margin-32;
}

@mixin list__link {
  display: block;
  transition: transform 0.2s ease;
  color: $c-black;
  text-decoration: none;

  &:hover,
  &:focus {
    transform: scale(1.05);
    color: $c-black;
  }
}

@mixin list__avatar {
  display: block;
  box-sizing: content-box;
  width: 7rem;
  height: 7rem;
  margin-bottom: $margin-16;
  border: solid 0.5rem $c-background;
  border-radius: 100%;
  background-color: $c-background;
  object-fit: cover;
  object-position: center;
}

@mixin list__name {
  display: block;
  font-size: $fs-large;
  text-align: center;
}
