.modal-link {
  margin-left: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $c-blue-dark;
  font-weight: normal;
  text-decoration: underline;
}

.big-modal {
  .modal-dialog {
    width: 100%;
    max-width: 1032px;
  }

  .modal-content {
    padding: 2.5em;
  }

  .modal-header {
    margin-bottom: 2.5em;
    padding-bottom: 2.5em;
    padding-left: 0.4em;
    border-bottom: solid 1px lightgray;

    .modal-title {
      font-size: 1.125rem;
    }

    .btn-close {
      background-size: 0.75rem;
    }
  }

  .modal-body {
    padding-bottom: 0;
  }
}
