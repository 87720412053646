@use "../variables" as *;

.transcript-display {
  font-size: $fs-larger;
  line-height: 2.1;
}

.transcript-display--edit-mode {
  padding: $margin-8;
  border: $border;
  border-radius: $border-radius;

  &:focus-within {
    border-color: $c-blue;
  }
}

.transcript-display__controls {
  display: flex;
  gap: $margin-8;
  margin-bottom: $margin-16;
}

.transcript-word__word {
  position: relative;

  span {
    position: relative;
    outline-offset: 0.2em;
  }

  &::before {
    content: "";
    position: absolute;
    top: -0.25em;
    left: -0.25em;
    width: calc(100% + 0.5em);
    height: calc(100% + 0.5em);
    transition: background-color 0.2s;
    background-color: transparent;
  }

  .transcript-word--highlight &::before {
    background-color: $c-green-pale;
  }
}

.transcript-word--difficult .transcript-word__word span {
  font-family: ITCStoneInformalSemiBold, serif;
  text-decoration: underline;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 0.07em;
}

.transcript-word__difficult-modal-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.transcript-word__whitespace--combinable {
  position: relative;
  margin: 0 0.2em;
  transition: background-color 0.3s;
  border-radius: $border-radius;
  background-color: $c-blue;
  cursor: pointer;

  &::before {
    content: "x";
    position: absolute;
    top: -1.5em;
    left: -0.1em;
    transition: opacity 0.3s, color 0.3s;
    opacity: 0;
    color: $c-blue;
    font-size: 0.8em;
  }

  &:hover {
    background-color: $c-red;

    &::before {
      opacity: 1;
      color: $c-red;
    }
  }
}

.transcript-word__difficult-modal .modal-dialog {
  width: fit-content;
  height: fit-content;
  border-radius: $border-radius;

  .modal-content,
  .modal-body {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
  }
}
