@use "../../../common/variables" as *;
@use "./../decoding/decoding-overview" as *;

.reading-comprehension-overview {
  @extend .decoding-overview;
}

.reading-comprehension-overview__info {
  @extend .decoding-overview__info;
}

.reading-comprehension-overview__info-small {
  @extend .decoding-overview__info-small;
}

.reading-comprehension-overview__start-button {
  @extend .decoding-overview__start-button;
}

.reading-comprehension-overview__guide {
  font-size: 1.5rem;
}
