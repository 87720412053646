@use "../avatar-list";
@use "../../common/variables" as *;

.school-group-list {
  @include avatar-list.list;
}

.school-group-list__link {
  @include avatar-list.list__link;
}

.school-group-list__group-avatar {
  @include avatar-list.list__avatar;
}

.school-group-list__group-avatar-placeholder {
  @include avatar-list.list__avatar;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-gray-dark;
  font-size: 4rem;
}

.school-group-list__group-name {
  @include avatar-list.list__name;
}
