@use "../variables" as *;

$navigation-padding: $margin-tiny;

.main-layout {
  display: flex;
  min-height: 100vh;
  background-color: $c-background;
}

.main-layout__navigation {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: 10.9375rem;
  max-height: 100vh;
  padding: $navigation-padding;
  overflow: auto;
  background: transparent linear-gradient(180deg, $c-blue-dark 0%, $c-blue 100%) 0% 0% no-repeat padding-box;

  @supports (max-height: 100dvh) {
    max-height: 100dvh;
  }
}

.main-layout__site-logo {
  display: block;
  margin: 2rem $navigation-padding 4.0595rem;

  img {
    width: 100%;
    pointer-events: none;
  }
}

.main-layout__menu {
  width: calc(100% + $navigation-padding);
  margin: 0;
  padding: 0;
  color: $c-white;
  list-style: none;
}

.main-layout__menu-link {
  display: block;
  margin-bottom: 1.872rem;
  padding: 0.628rem 0 0.628rem 1.25rem;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: $border-radius 0 0 $border-radius;
  color: $c-white;
  text-decoration: none;

  &:hover {
    background-color: $c-background;
    color: $c-blue-dark;
  }
}

.main-layout__menu-link--active {
  background-color: $c-background;
  color: $c-blue-dark;

  &:hover {
    background-color: $c-background;
    color: $c-blue-dark;
  }
}

.main-layout__menu-link-icon {
  margin-right: 0.75rem;
}

.main-layout__main-container {
  width: 100%;
  overflow: auto;
}

.main-layout__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 4.25rem;
  padding-right: $margin-large;
  border-bottom: solid 0.125rem transparent;
  background-color: $c-white;
  border-image: $brand-colors-image 1;
}

.main-layout__header__reset-button {
  margin-right: 1rem;
}

.main-layout__content {
  padding: $margin-medium $margin-large $margin-small $margin-small;
}
