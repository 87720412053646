@use "../../../common/variables" as *;

.show-hidden-objects-game {
  .vocabulary-layout__content {
    position: relative;
  }

  .hidden-objects-game-display {
    display: flex;
  }

  .hidden-objects-game-display__container {
    margin: 0 auto;
  }

  .hidden-objects-game-display__full-image {
    max-height: 72vh; // Make sure it always fits on the screen
    border-radius: $border-radius;
  }
}

.hidden-objects-game-overlay {
  display: flex;
  position: absolute;
  top: -1%;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 102%;
  backdrop-filter: blur(2px);
}

.hidden-objects-game-overlay__box {
  width: 50%;
  padding: $margin-48 $margin-64;
  border-radius: $border-radius-large;
  background-color: $c-white;
  text-align: center;
}

.hidden-objects-game-overlay__title {
  margin-bottom: $margin-32;
  font-size: $fs-xlarge;
  font-weight: bold;
}

.hidden-objects-game-overlay__image {
  display: block;
  max-width: 100%;
  height: 12.5rem;
  margin: $margin-16 auto;
  object-fit: contain;
}

.hidden-objects-game-overlay__word {
  font-size: $fs-xlarge;
}

.hidden-objects-game-overlay__close-button {
  margin: $margin-40 auto 0;
}

.finished-display {
  text-align: center;
}

.finished-display__image {
  height: calc(90vh - 12rem); // -12rem for all stuff above and some paddings everywhere
}

.finished-display__title {
  margin-bottom: $margin-32;
  font-size: $fs-xxlarge;
}
