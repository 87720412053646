@use "../../../common/variables" as *;

.progress-result {
  display: flex;
  gap: 1rem;
}

.progress-result-item {
  .icon {
    margin-right: 0.5rem;
  }

  .icon--check-circle {
    color: map-get($status-colors, "finished");
  }

  .icon--x-circle {
    color: map-get($status-colors, "retry");
  }

  .icon--progress {
    color: map-get($status-colors, "started");
  }
}
