$c-white: #fff;
$c-gray-light-light: #f7f7f7;
$c-gray-light: darken(#f5fafd, 5%);
$c-gray: #939598;
$c-gray-dark: #6f6f71; // Was #77787a but replaced for better contrast on $c-background
$c-black: #231f20;
$c-black-translucent: #231f2057;
$c-red: rgb(225 1 64);
$c-red-dark: rgb(165 0 47);
$c-green-pale: #dcedc4;
$c-green-light: #8dc63f;
$c-green: rgb(0 128 0);
$c-green-dark: rgb(0 95 0);
$c-yellow-extra-light: #fffcd5;
$c-yellow: rgb(255 194 14);
$c-orange: rgb(242 101 34);
$c-blue-extra-light: #e1f4fd;
$c-blue-light: #b1ddf0;
$c-blue: #0091d0;
$c-blue-dark: #0e74a1;

$c-background: #f3f5f7;
$c-border: #d0d0d0;

$margin-tiny: 1rem;
$margin-small: 2.0625rem;
$margin-medium: 2.8125rem;
$margin-large: 4rem;

$margin-6: 0.375rem;
$margin-8: 0.5rem;
$margin-12: 0.75rem;
$margin-16: 1rem;
$margin-20: 1.25rem;
$margin-24: 1.5rem;
$margin-28: 1.75rem;
$margin-32: 2rem;
$margin-40: 2.5rem;
$margin-48: 3rem;
$margin-56: 3.5rem;
$margin-64: 4rem;
$margin-80: 5rem;

$fs-small: 0.75rem; // 12px
$fs-default: 1rem; // 16px
$fs-large: 1.125rem; // 18px
$fs-larger: 1.25rem; // 20px
$fs-xlarge: 1.5rem; // 24px
$fs-xxlarge: 2rem; // 32px
$fs-h2: 1.25rem; // 20px
$fs-h3: $fs-large;
$fs-h4: $fs-default;
$fs-label: $fs-large;

$border-radius: 0.625rem; // 10px
$border-radius-small: 0.3125rem; // 5px
$border-radius-large: 1.875rem; // 30px
$border: solid 0.0625rem $c-border;
$border-dark: solid 0.0625rem $c-gray;
$drop-shadow: 0 0.1875rem 0.375rem #00000029;
$outer-shadow: 0 0 0.375rem #00000029;
$brand-colors-image:
  linear-gradient(
    90deg,
    $c-red 25%,
    $c-green 25%,
    $c-green 50%,
    $c-yellow 50%,
    $c-yellow 75%,
    $c-orange 75%
  );

$status-colors: (
  "finished": $c-green,
  "started": $c-orange,
  "retry": $c-red,
  "disabled": $c-gray,
  "locked": $c-background,
  "new": $c-blue,
);

/* stylelint-disable value-keyword-case */
$ITCStoneFont: ITCStoneInformalMedium, serif;
$ITCStoneFontSemiBold: ITCStoneInformalSemiBold, serif;
/* stylelint-enable value-keyword-case */
