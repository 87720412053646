@use "../variables" as *;

.text-display {
  max-width: 75ch;
  min-height: 15rem;
  max-height: calc(100vh - 21rem);
  padding: 1rem;
  overflow: auto;
  border: $border;
  border-radius: $border-radius;
  font-size: $fs-larger;
  line-height: 2.1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1.25em;
  }

  blockquote {
    padding-left: 1rem;
    border-left: $border;
    border-width: 0.125rem;
  }
}

.difficult-word {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ITCStoneInformalSemiBold, serif;
  text-decoration: underline;
  text-underline-offset: 0.15em;
  text-decoration-thickness: 0.07em;
}

.difficult-word__modal .modal-dialog {
  width: fit-content;
  height: fit-content;
  border-radius: $border-radius;

  .modal-content,
  .modal-body {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 10rem);
    border-radius: $border-radius;
  }
}
