@use "../../../common/variables" as *;
// $word-block-cell-size:  5.875rem;

.word-block-display {
  display: inline-flex;
  flex-direction: column;
}

.word-block-display__row {
  height: 5.875rem;
  // display: grid;
  // grid-template-rows: $word-block-cell-size;
  // grid-template-columns: repeat(auto-fit, $word-block-cell-size);
}

.word-block-display__group {
  $border-style: $border-dark;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0 $margin-32;
  transition: background-color 0.3s ease;
  border: $border-style;
  border-bottom: none;
  border-left: none;
  font-size: $fs-xxlarge;
  font-weight: bold;
  // grid-column: span var(--span, 1);

  .word-block-display__part {
    visibility: hidden;
    transition: visibility 0s ease 0.3s, opacity 0.3s ease;
    opacity: 0;
    font-family: $ITCStoneFont;
  }

  .word-block-display__row--active & {
    background-color: $c-yellow-extra-light;

    .word-block-display__part {
      visibility: visible;
      transition: visibility 0s ease 0s, opacity 0.3s ease;
      opacity: 1;
    }
  }

  &:first-child {
    border-left: $border-style;
  }

  .word-block-display__row:last-child & {
    border-bottom: $border-style;
  }
}
