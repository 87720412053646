@use "../variables" as *;
@use "../utils";

.audio-input__record-button.audio-input__record-button--recording {
  transition: none;
  color: $c-red;

  .icon {
    display: block;
    animation-name: recording;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  svg {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    fill: currentcolor;
  }

  &:hover,
  &:focus-visible {
    background-color: transparent;
    color: $c-red;
  }
}

@keyframes recording {
  from { transform: scale(0.8); }
  to { transform: scale(1); }
}

.audio-input__playback {
  @include utils.visually-hidden;
}

.multi-audio-input__wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .file-input {
    flex-grow: 1;
  }
}

.multi-audio-input__lang-label {
  flex-shrink: 0;
  margin-right: $margin-8;
  margin-bottom: $margin-20;
  color: $c-blue;
}
