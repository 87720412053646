@use "../../../common/variables" as *;

.modal-lesson {
  position: relative;
  width: fit-content;
}

.modal-lesson--with-arrows {
  margin-top: 2.5em;
}

.modal-lesson__text {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold;
  gap: 10px;
}

.modal-lesson__text-img {
  height: 40px;
  margin-top: -15px;
}

.modal-lesson__text-dot {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: $c-black;
}

.modal-lesson__arrow-1 {
  top: -30px;
  left: 90px;

  .modal-arrow__arrow {
    width: 186px;
    height: 26px;
  }
}

.modal-lesson__arrow-2 {
  top: 50%;
  left: calc(100% + 20px);
  transform: translateY(-50%);

  .modal-arrow__arrow {
    width: 190px;
    height: 1px;
  }
}
