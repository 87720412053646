@use "../variables" as *;
@use "../utils";

.file-input {
  margin-bottom: $margin-20;
}

.file-input__drop-zone {
  position: relative;
  min-height: 3rem;
  overflow: hidden;
  transition: border 0.3s ease;
  border: $border;
  border-radius: $border-radius;
  background-color: $c-white;

  &::after {
    content: "Laat los om te uploaden";
    display: flex;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0s 0.3s;
    opacity: 0;
    background-color: rgba($c-blue, 0.5);
    pointer-events: none;
  }
}

.file-input__drop-zone--hovering {
  border-color: $c-blue;

  &::after {
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s;
    opacity: 1;
  }
}

.file-input__spinner-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.file-input__buttons {
  display: flex;
  align-items: center;
}

.file-input--processing .file-input__buttons {
  display: none;
}

.file-input__file-name {
  display: inline-block;
  margin: 0 $margin-6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-input__button {
  height: 2.875rem;
  margin: 0;
  padding: 0 $margin-16;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 0;
  background-color: transparent;
  color: $c-blue;

  &:hover,
  &:focus-visible {
    background-color: $c-blue;
    color: $c-white;
  }

  .icon {
    // Click trough icon so the icon does not fire mouse events
    pointer-events: none;
  }
}

.file-input__button--delete {
  margin-left: auto;
}

.file-input__file-input,
.file-input__button-label {
  @include utils.visually-hidden;
}
