@use "../variables" as *;

.school-selector__page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: $margin-80 $margin-16 $margin-16;
  background: $c-background;
}

.school-selector__title {
  font-size: $fs-larger;
}

.school-selector__help-text {
  text-align: center;
}

.school-selector__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 26.5rem;
  margin-bottom: $margin-32;
  padding: $margin-40 $margin-32;
  border: 1px solid $c-border;
  border-radius: $border-radius;
  background-color: $c-white;
}

.school-selector__login-info {
  color: $c-gray-dark;
}

.school-selector__form {
  width: 100%;
}

.school-selector__input-row {
  display: flex;
  margin-top: 1rem;

  .input-group {
    flex: 1 1 100%;
    width: 50%;
  }
}

.school-selector__button {
  flex-shrink: 0;
  padding: 0 1.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:disabled {
    color: $c-white;
  }
}

.school-selector__wrapper .input-group .icon--search {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  color: $c-gray-dark;
}

.school-selector__wrapper .input-group .form-control {
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.school-selector__logo {
  width: 75vw;
  max-width: 15rem;
  margin-bottom: $margin-40;
}

.school-selector__last-school {
  margin-top: $margin-16;
}
