@use "../variables" as *;

.module-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: $margin-80 $margin-16 $margin-16 $margin-16;
  background-color: $c-background;
  gap: $margin-40;
}

.module-selector__logo {
  width: 50%;
  max-width: 15rem;
}

.module-selector__container {
  width: 100%;
  max-width: 26.5rem;
  padding: $margin-40 $margin-32;
  border: $border;
  border-radius: $border-radius;
  background-color: $c-white;
  text-align: center;
}

.module-selector__title {
  font-size: $fs-larger;
}

.module-selector__schools {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: $margin-8;
}

.module-selector__link {
  display: block;
  width: 100%;
  padding: $margin-16;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease;
  border: 0;
  border-radius: $border-radius;
  color: $c-black;
  text-decoration: none;
}

.module-selector__link--school {
  padding: $margin-8;
  border: $border;
  background-color: transparent;

  &:hover,
  &:focus-visible {
    border-color: $c-green;
    color: $c-green;
  }
}

.module-selector__link--login,
.module-selector__link--admin {
  margin-top: $margin-32;
  background-color: $c-blue;
  color: $c-white;

  &:hover,
  &:focus {
    background-color: $c-blue-dark;
    color: $c-white;
  }
}

.module-selector__sign-out {
  display: block;
  margin: $margin-8 auto 0;
  padding: 0;
  transition: color 0.3s ease;
  border: 0;
  background-color: transparent;
  color: $c-blue;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $c-blue-dark;
  }
}
