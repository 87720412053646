@use "../../../common/variables" as *;
@use "../../../common/utils" as *;
@use "../decoding/decoding-layout" as *;

.video-layout {
  @extend .decoding-layout;
}

.video-layout__header {
  @extend .decoding-layout__header;

  position: absolute;
  top: 2rem;
  right: 5rem;
  width: fit-content;
}

.video-layout__progress-bar {
  @extend .decoding-layout__progress-bar;
}

.video-layout__progress {
  @extend .decoding-layout__progress;
}

.video-layout__title {
  @extend .decoding-layout__title;
}

.video-layout__title-image {
  @extend .decoding-layout__title-image;
}

.video-layout__title-text {
  @extend .decoding-layout__title-text;
}
