.input__search--user {
  height: 3.125rem;
  padding: 0;
  border: 1px solid $c-border;
  border-radius: $border-radius;

  .input-group-text {
    height: 3rem;
    padding-right: 0;
    border: none;
    background: $c-white;
    color: $c-green;
    font-size: 0.8em;

    &#clear-icon {
      padding-right: $margin-12;
      padding-left: 0;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .form-control {
    height: 3rem;
    border: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &:focus-within {
    border-color: $c-green;
  }
}
