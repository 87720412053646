@use "../../../common/variables" as *;

.decoding-overview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
}

.decoding-overview__info {
  margin-bottom: $margin-40;
  font-size: $fs-xlarge;
}

.decoding-overview__info-small {
  margin-bottom: $margin-16;
  font-size: $fs-larger;
}

.decoding-overview .goal-blocks:not(:last-of-type) {
  margin-bottom: $margin-56;
}

.decoding-overview__start-button {
  align-self: flex-end;
  margin-top: auto;
  margin-left: auto;
}
