@use "../../common/variables" as *;

.edit-lesson__container {
  display: grid;
  grid-template:
    "main main aside"
    "meta meta aside"
    "details details aside";
  grid-template-columns: 1fr 1fr 19.6rem;
  gap: 0 $margin-20;
  grid-template-rows: min-content min-content 1fr;
}

.edit-lesson__form {
  display: contents;
}

.edit-lesson__basic-info {
  grid-area: main;
  margin-top: -1rem;
}

.edit-lesson__submit-box-container {
  grid-area: aside;
  position: relative;
}

.edit-lesson__submit-box {
  position: sticky;
  top: $margin-20;

  .form-select {
    border-radius: $border-radius-small;
  }
}

.edit-lesson__edit-meta {
  grid-area: meta;
  min-width: 0;
}

.edit-lesson__edit-document {
  grid-area: details;
  min-width: 0;
}

.edit-lesson__select-published-state {
  margin-bottom: $margin-12;
}

.edit-lesson__save-button {
  width: 100%;
  margin-bottom: $margin-20;
}

.edit-lesson__last-edited {
  font-size: $fs-label;
  text-align: center;
}

.edit-lesson__last-edited-label {
  display: block;
}

.edit-lesson__delete-lesson-button {
  display: block;
  margin: 0 auto;
}
