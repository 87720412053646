@use "../../common/variables" as *;

.list-instruction-label__item-key {
  display: flex;
  align-items: center;
  font-size: $fs-large;
  font-weight: bold;
}

.list-instruction-label__submit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.9rem;
}
