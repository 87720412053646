@use "../variables" as *;

.recording-player {
  display: flex;
}

.recording-player__play-button-icon {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: $c-red;
  font-size: 0.7em;
}

.recording-player__play-button {
  display: inline-flex;
  align-items: center;
  padding: 0;
  transition: color 0.3s ease;
  border: 0;
  background-color: transparent;

  &:hover .recording-player__play-button-icon,
  &:focus-visible .recording-player__play-button-icon {
    color: $c-red-dark;
  }
}
