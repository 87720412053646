@use "../common/variables" as *;

.list-lesson-items__header {
  display: grid;
  grid-template-columns: 3rem auto 1fr auto;
  align-items: center;
  gap: $margin-24;
  margin-bottom: $margin-40;
}

.list-lesson-items__header__back-button {
  width: fit-content;
  padding: 0.5rem;
  border: 1px solid $c-black;
  border-radius: 0.5rem;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

.list-lesson-items__title-image {
  width: 2.5rem;
}

.list-lesson-items__title {
  margin: 0;
  font-size: $fs-xlarge;
  font-weight: bold;
}

.list-lesson-items__instructions {
  margin-bottom: $margin-40;
  font-size: $fs-xlarge;
}

.decoding-lesson-list,
.vocabulary-lesson-list,
.strong-reader-lesson-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: $margin-24;
}

.decoding-lesson-list__tile-link,
.vocabulary-lesson-list__tile-link,
.strong-reader-lesson-list__tile-link {
  display: block;
  transition: transform 0.2s ease;
  border-radius: $border-radius;
  color: $c-black;
  text-decoration: none;

  &:hover,
  &:focus {
    transform: scale(1.05);
    color: $c-black;
  }
}

// Decoding tile

.decoding-lesson-list__tile {
  position: relative;
  border-radius: $border-radius-large;
  color: $c-black;
}

.decoding-lesson-list__tile-content,
.strong-reader-lesson-list__tile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 7.75rem;
  padding: $margin-16;
  border-radius: $border-radius;
  background-color: $c-background; // Disabled state
  box-shadow: $drop-shadow;
  aspect-ratio: 1/1;

  .decoding-lesson-list__tile-link & {
    background-color: $c-white; // Enabled state
  }
}

.decoding-lesson-list__tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 120%;
  left: 50%;
  box-sizing: content-box;
  width: 140%;
  padding: $margin-16 $margin-24;
  transform: translateX(-50%);
  transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  border-radius: $border-radius-large;
  opacity: 0;
  background-color: $c-white;
  box-shadow: $drop-shadow;
  font-size: $fs-larger;
  text-align: center;

  .decoding-lesson-list__tile:hover &,
  .decoding-lesson-list__tile-content:focus & {
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s;
    opacity: 1;
  }
}

.decoding-lesson-list__tooltip-arrow,
.strong-reader-lesson-list__tooltip-arrow {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2rem;
  height: 1rem;
  overflow: hidden;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 50%;
    height: 100%;
    transform: translateX(-50%) translateY(50%) rotate(45deg);
    background-color: $c-white;
    box-shadow: $drop-shadow;
  }
}

.decoding-lesson-list__tooltip-icon,
.strong-reader-lesson-list__tooltip-icon {
  color: $c-blue;
}

.decoding-lesson-list__tile-header,
.strong-reader-lesson-list__tile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.decoding-lesson-list__tile-status-icon,
.strong-reader-lesson-list__tile-status-icon {
  font-size: 0.85rem;
}

.decoding-lesson-list__tile-title,
.strong-reader-lesson-list__tile-title {
  font-size: $fs-large;
}

.decoding-lesson-list__title-image,
.strong-reader-lesson-list__title-image {
  width: 100%;
  height: 4rem;
  object-fit: contain;
  object-position: left;

  &.icon {
    display: block;
    font-size: 2.5rem;
    text-align: center;
  }
}

.decoding-lesson-list__tile--state-finished .decoding-lesson-list__tile-status-icon,
.strong-reader-lesson-list__tile--state-finished .strong-reader-lesson-list__tile-status-icon {
  color: map-get($status-colors, "finished");
}

.decoding-lesson-list__tile--state-started .decoding-lesson-list__tile-status-icon,
.strong-reader-lesson-list__tile--state-started .strong-reader-lesson-list__tile-status-icon {
  color: map-get($status-colors, "started");
}

.decoding-lesson-list__tile--state-disabled .decoding-lesson-list__tile-status-icon,
.strong-reader-lesson-list__tile--state-disabled .strong-reader-lesson-list__tile-status-icon {
  color: map-get($status-colors, "disabled");
}

.decoding-lesson-list__tile--state-retry,
.strong-reader-lesson-list__tile--state-retry {
  .decoding-lesson-list__tile-status-icon,
  .strong-reader-lesson-list__tile-status-icon {
    color: map-get($status-colors, "retry");
  }

  .decoding-lesson-list__tile-content,
  .strong-reader-lesson-list__tile-content {
    box-shadow: 0 0 0.375rem map-get($status-colors, "retry");
  }
}

.decoding-lesson-list__tile--state-locked,
.strong-reader-lesson-list__tile--state-locked {
  .decoding-lesson-list__tile-content,
  .strong-reader-lesson-list__tile-content {
    background-color: map-get($status-colors, "locked");
  }

  .decoding-lesson-list__title-image,
  .strong-reader-lesson-list__title-image {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;

    svg {
      stroke-width: 0.09rem;
    }
  }
}

.decoding-lesson-list__tile--state-new,
.strong-reader-lesson-list__tile--state-new {
  .decoding-lesson-list__title-image,
  .strong-reader-lesson-list__title-image {
    color: map-get($status-colors, "new");

    svg {
      stroke-width: 0.08rem;
    }
  }
}

// Vocabulary tile

.vocabulary-lesson-list__tile-content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 17rem;
  height: 11.44rem;
  overflow: hidden;
  border-radius: $border-radius;
  background-color: $c-white;
  box-shadow: $outer-shadow;
}

.vocabulary-lesson-list__title-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.vocabulary-lesson-list__title-title {
  display: inline-block;
  position: absolute;
  top: $margin-16;
  left: $margin-16;
  max-width: 100%;
  margin-right: $margin-16;
  padding: 0.5rem $margin-12;
  border-radius: 1.0625rem;
  background-color: $c-white;
  line-height: 1;
}

// Books
.book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  gap: $margin-16;
  padding: $margin-8 0;
  list-style-type: none;
}

.book-list__tile-image {
  width: 100%;
  transition: all 0.3s ease-in-out;
  box-shadow: $outer-shadow;
}

.book-list__tile-button {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  &:hover .book-list__tile-image {
    transform: scale(1.05);
  }
}

//swiper
.swiper {
  height: 100%;
}

.swiper-slide {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: unset;
  padding: 0.5rem;
  gap: 1.5rem;
}

.swiper-wrapper {
  margin-bottom: 4rem;
}

.swiper-scrollbar,
.swiper-button-next,
.swiper-button-prev {
  display: none;
}
