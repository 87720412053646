@use "../../common/variables" as *;

.goal-blocks {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.goal-blocks__block {
  $border: $border-dark;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0 $margin-24;
  border: $border;
  border-right: 0;
  background-color: $c-blue-extra-light;
  font-family: $ITCStoneFont;
  font-size: $fs-xxlarge;
  font-weight: bold;
  text-align: center;

  &:last-child {
    border-right: $border;
  }
}
