@use "../../../common/variables" as *;

.reading-comprehension-questions .reading-comprehension-layout__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.reading-comprehension-questions .question-display {
  flex-shrink: 0;
}

.reading-comprehension-questions__transcript {
  align-self: flex-start; // dvh fallback
  max-height: calc(100dvh - 22rem); // 22rem is kinda about the space used by other elements
  padding: $margin-32;
  overflow: auto;
  border-radius: $border-radius-large;
  background-color: $c-gray-light-light;

  .transcript-display {
    margin: 0;
    font-size: $fs-xlarge;
  }
}

.decoding-layout .question-display__question,
.question-display__answer-radio {
  font-family: $ITCStoneFont;
  font-size: $fs-xlarge;
  line-height: 2.1;
}

.question-display__answer-radio {
  .form-check-label,
  .form-check-input {
    cursor: pointer;
  }

  .form-check-input {
    margin-top: 0.8rem;
  }
}

.question-result-modal__container {
  text-align: center;
}

.question-result-modal .modal-content {
  padding: $margin-56 $margin-64 $margin-40;
  border-radius: $border-radius-large;
}

.question-result-modal__title,
.question-result-modal__sub-title {
  font-family: $ITCStoneFontSemiBold;
  font-size: $fs-xlarge;
}

.question-result-modal__sub-title {
  margin-bottom: $margin-32;
}

.question-result-modal__main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: $fs-xlarge;
  line-height: 2.1;
  gap: clamp($margin-16, 5vw, 3.25rem);
}

.question-result-modal__answer-source {
  flex-shrink: 0;
  font-family: $ITCStoneFont;
  text-align: start;

  p {
    white-space: no-wrap;
  }
}

.question-result-modal__highlight {
  position: relative;
  font-family: $ITCStoneFontSemiBold;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -0.25em;
    left: -0.25em;
    width: calc(100% + 0.5em);
    height: calc(100% + 0.5em);
    background-color: $c-green-pale;
  }

  span {
    position: relative;
  }
}

.question-result-modal__vlam {
  flex-shrink: 1;
  height: 13.8125rem;
}

.question-result-modal__close-button {
  margin-top: $margin-32;
  margin-left: auto;
}
