@use "../../../../common/variables" as *;

.reading-comprehension__editor-transcript {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: $border;
  border-radius: $border-radius;
}

.reading-comprehension__delete-word-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reading-comprehension__add-word-button {
  width: fit-content;
  border: none;
  background: none;
  color: $c-blue;

  &:hover {
    color: $c-blue-dark;
  }

  &[disabled] {
    color: $c-gray;
    cursor: not-allowed;

    &:hover {
      color: $c-gray;
    }
  }
}
