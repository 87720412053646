@use "../../../common/variables" as *;
@use "../../../common/utils" as *;

.decoding-layout {
  @include pupil-font;

  display: flex;
  flex-direction: column;
  max-width: 92rem;
  min-height: 100vh;
  margin: 0 auto;
  padding: $margin-28 $margin-80 $margin-48;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
}

.decoding-layout__header {
  display: flex;
  gap: $margin-40;
  align-items: center;
  margin-bottom: $margin-28;
}

.decoding-layout__progress-bar {
  position: relative;
  width: 100%;
  height: 0.9375rem;
  overflow: hidden;
  border-radius: $border-radius;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: $border-dark;
    border-radius: inherit;
  }
}

.decoding-layout__progress {
  position: relative;
  height: 100%;
  transition: width 0.3s ease;
  background-color: $c-blue;
}

.decoding-layout__title {
  display: flex;
  align-items: center;
  gap: $margin-24;
  margin-bottom: $margin-24;
}

.decoding-layout__title-image {
  width: 2.5rem;
}

.decoding-layout__title-text {
  margin: 0;
  font-size: $fs-xlarge;
}
