@use "../variables" as *;

.select-layout__header {
  display: grid;
  align-items: center;
  grid-template-columns: 10rem 1fr 13rem fit-content(10rem);
  gap: 1rem;

  .btn.btn-primary {
    width: 13rem;
    height: fit-content;
  }
}

.select-layout__signout-button,
.select-layout__header-back-button {
  width: 3rem;
  padding: 0.69rem;
  border: 1px solid $c-black;
  border-radius: 0.5rem;
  background-color: transparent;
}

.select-layout__signout-button {
  justify-self: flex-end;
}

.select-layout__logo {
  display: block;
  width: 6.75rem;
  height: 5.875rem;
  margin: 0 auto $margin-40;
  object-fit: contain;
  object-position: center;
}

.select-layout__action {
  margin-bottom: $margin-32;
  text-align: center;
}

.select-layout__action--start {
  text-align: start;
}

.select-layout__action-text {
  font-size: $fs-xlarge;
  font-weight: 400;
}
