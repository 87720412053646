@use "../variables" as *;

.page-title {
  display: flex;
  margin-bottom: $margin-small;
}

.page-title__link {
  margin-right: 0.3rem;
  transition: color 0.3s ease;
  color: $c-black;

  &:hover,
  &:focus-visible {
    color: $c-blue;
  }

  .icon {
    margin-left: -0.4em; // The icon has some padding causing it to not be against the page edge
  }
}

.page-title__main-title {
  display: block;
}

.page-title__sub-title {
  display: block;
  margin-top: 0.3rem;
  font-size: 1rem;
  font-weight: normal;
}
