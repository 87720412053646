@use "../variables" as *;

.box {
  margin-bottom: $margin-32;
  border: $border;
  border-radius: $border-radius-small;
  background-color: $c-white;
}

.box__header,
.box-divider {
  padding: $margin-20;
  border-bottom: $border;
  border-width: 0.125rem;
}

.box-divider--no-title {
  padding: 0;
  border-bottom: 0;
}

.box__header {
  display: flex;
  justify-content: space-between;
}

.box-divider__title,
.box__title {
  margin: 0;
  font-size: $fs-label;
}

.box__body {
  padding: $margin-20;
}

.box-divider {
  display: flex;
  justify-content: space-between;
  margin: $margin-20 (-$margin-20);
  border-top: $border;
  border-width: 0.125rem;
}

.row + .box-divider {
  // Emulate margin collapsing for dividers that follow a .row (flex)
  margin-top: 0;
}
