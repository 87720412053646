@use "../../common/variables" as *;
@use "../../common/utils";

.pupil-progress__dropdown-toggle.btn.btn-primary {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  &:hover,
  &:focus,
  &:disabled,
  &.disabled {
    opacity: 1;
    background-color: transparent;
  }
}

.pupil-progress-table-status {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
}

.status-message {
  @include utils.status-color-modifiers();
}

.pupil-progress__dropdown-danger-item {
  color: $c-red;

  &:hover {
    color: $c-red;
  }

  &:focus {
    color: $c-white;
  }
}

.pupil-progress__dropdown-bulk-items {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: solid 0.1rem $c-gray;

  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
