$password-image-height: 3.125rem;

td {
  vertical-align: middle;
}

.user-table__row--user-info {
  display: grid;
  gap: $margin-8;
  align-items: center;
  height: $password-image-height;
}

.user-table__row--user-password {
  display: grid;
  grid-template-columns: repeat(4, $password-image-height);
  gap: $margin-8;

  .user-table__row--user-password-image {
    width: $password-image-height;
    height: $password-image-height;
    border: 1px solid $c-border;
  }
}

.user-table__row--edit-button.btn,
.user-table__row--print-button.btn {
  margin: 0 $margin-8;
  padding-left: 0;
  color: $c-green;
  text-align: center;
  text-decoration: none;

  .icon {
    margin: 0;
    margin-right: $margin-8;
  }

  &:focus,
  &:hover {
    box-shadow: none;
    color: $c-green-dark;
  }
}

.user-table__row {
  $icon-width: 1.8rem;

  &.user-table__row--user,
  &.user-table__row--group {
    .user-table__row--image {
      width: $icon-width;
      height: $icon-width;
      border: 1px solid $c-border;
      border-radius: 100%;
    }
  }

  &.user-table__row--user {
    .user-table__row--user-info {
      padding-left: calc($margin-40 + $icon-width + $margin-8);
      grid-template-columns: $icon-width auto;
    }
  }

  &.user-table__row--group {
    &:hover {
      cursor: pointer;
    }

    .user-table__row--user-info {
      padding-right: $icon-width + $margin-16;
      padding-left: $margin-40;
      grid-template-columns: $icon-width $icon-width auto $icon-width;
    }
  }
}
