@use "../variables" as *;
@use "../utils";
$round-button--width: 3.5rem;
$round-button--height: 3.5rem;

.round-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 3.5rem;
  min-height: 3.5rem;
  transition: background-color 0.3s ease;
  border: 0.125rem solid transparent;
  border-radius: 3.5rem;
  background-color: $c-blue;
  color: $c-white;
  font-size: $fs-default;

  &:disabled {
    background-color: $c-gray;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: $c-blue-dark;
  }

  &::before {
    content: "";
    position: absolute;
    top: -10%;
    min-width: 3.75rem;
    min-height: 3.75rem;
    background-color: transparent;
  }
}

.round-button .icon {
  font-size: $fs-default;
}

.round-button.round-button--show-label {
  display: flex;
  gap: 0.5rem;
  padding: $margin-12 $margin-16 $margin-12 $margin-24;

  .round-button__label {
    font-size: $fs-xlarge;
    line-height: 1;
  }
}

.round-button--danger {
  background-color: $c-red;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: $c-red-dark;
  }
}

.round-button--success {
  background-color: $c-green;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: $c-green-dark;
  }
}

.round-button__icon svg {
  stroke-width: 3;
}

.round-button:not(.round-button--show-label) .round-button__label {
  @include utils.visually-hidden;
}

.round-button--large {
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 4rem;
  font-size: 1.5rem;

  svg {
    stroke-width: 1.5;
  }
}
