@use "../../common/variables" as *;

.actionsRow {
  display: flex;
  gap: 1rem;
}

.passwordRequirement {
  .icon {
    margin-right: $margin-6;
  }

  &.passwordRequirement--meets {
    color: $c-green;
  }

  &.passwordRequirement--failed {
    color: $c-red;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mantine-InputWrapper-root {
  margin-bottom: $margin-16;
}

.mantine-PasswordInput-label {
  margin-bottom: $margin-6;
}

.mantine-PasswordInput-wrapper {
  position: relative;
  width: 16rem;

  .mantine-PasswordInput-innerInput {
    width: 100%;

    &[data-invalid="true"] {
      border-color: $c-red;
    }
  }

  .mantine-PasswordInput-section {
    position: absolute;
    top: 0;
    right: 0;

    // Show password button
    button {
      width: 1.5rem;
      height: 1.5rem;
      margin: 3px 3px 0 0;
      padding: 0;
      border: 0;
      border-radius: $border-radius-small;
      background-color: transparent;
      vertical-align: middle;

      &:hover {
        background-color: $c-gray-light;
      }

      svg {
        /* stylelint-disable declaration-no-important */
        width: 1.25rem !important; // Override mantine styling on element
        height: 1.25rem !important; // Override mantine styling on element
        /* stylelint-enable declaration-no-important */
        padding-top: 4px;
        vertical-align: top;
      }
    }
  }
}

.mantine-InputWrapper-error {
  color: $c-red;
  font-size: $fs-default;
}

.mantine-Popover-dropdown {
  position: absolute;
  /* stylelint-disable declaration-no-important */
  top: 30px !important; // Override mantine styling on element
  left: 17rem !important; // Override mantine styling on element
  width: fit-content !important; // Override mantine styling on element
  /* stylelint-enable declaration-no-important */
  padding: $margin-12;
  border: 1px solid $c-gray;
  border-radius: $border-radius-small;
  background: $c-white;
}

.passwordProgress {
  width: 100%;
  height: 0.5rem;
  margin-bottom: $margin-16;
  overflow: hidden;
  border: 1px solid $c-black;
  border-radius: $border-radius-small;
  background-color: $c-gray-light-light;
}

.passwordProgress-bar {
  height: 0.5rem;
  transition: width 0.5s ease-out;
  background-color: $c-gray;

  &.passwordProgress-bar--invalid {
    background-color: $c-red;
  }

  &.passwordProgress-bar--almost-valid {
    background-color: $c-orange;
  }

  &.passwordProgress-bar--valid {
    background-color: $c-green;
  }
}
