@use "../../common/variables" as *;

.lesson-variant-selector {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  grid-template-columns: 1fr 1fr;
  gap: $margin-32;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
}

.lesson-variant-selector__item {
  display: flex;
  padding: $margin-32 $margin-48;
  transition: box-shadow 0.3s ease;
  border-radius: $border-radius-large;
  background-color: $c-white;
  box-shadow: $drop-shadow;
  color: $c-black;
  text-decoration: none;
  gap: 5rem;

  &:hover,
  &:focus {
    color: $c-black;
  }

  &:hover,
  &:focus-visible {
    box-shadow: $drop-shadow, $drop-shadow;
  }
}

.lesson-variant-selector__image {
  width: 13.3rem;
  height: 12.5rem;
  object-fit: contain;
  object-position: center;
}

.lesson-variant-selector__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lesson-variant-selector__level {
  font-size: $fs-h2;
  font-weight: 600;
}

.lesson-variant-selector__category {
  font-size: $fs-large;
}

.lesson-table__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.lesson-table__header-actions {
  display: grid;
  width: 55%;
  grid-template-columns: 1fr 1fr fit-content(20.75rem);
  gap: $margin-8;

  .form-select {
    margin-bottom: 0;
  }
}
