$arrow-side-border: solid 3px transparent;
$arrow-main-border: solid 6px #231f20;

.modal-arrow {
  position: absolute;
  font-size: 1.125rem;
  text-align: right;
  text-wrap: nowrap;
}

.modal-arrow__text {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.modal-arrow__arrow {
  &::after {
    content: "";
    position: absolute;
  }
}

.modal-arrow__arrow--left {
  border-top: solid 1px #231f20;

  &::after {
    top: 0.5px;
    left: -6px;
    transform: translateY(-50%);
    border-top: $arrow-side-border;
    border-right: $arrow-main-border;
    border-bottom: $arrow-side-border;
  }
}

.modal-arrow__arrow--left-bottom {
  border-top: solid 1px #231f20;
  border-left: solid 1px #231f20;

  &::after {
    bottom: -6px;
    left: -2.5px;
    border-top: $arrow-main-border;
    border-right: $arrow-side-border;
    border-left: $arrow-side-border;
  }
}

.modal-arrow__arrow--left-top {
  border-bottom: solid 1px #231f20;
  border-left: solid 1px #231f20;

  &::after {
    top: -6px;
    left: -2.5px;
    border-right: $arrow-side-border;
    border-bottom: $arrow-main-border;
    border-left: $arrow-side-border;
  }
}

.modal-arrow__arrow--left-top + .modal-arrow__text {
  bottom: 0;
}
