@use "../avatar-list";

.school-pupil-list {
  @include avatar-list.list;
}

.school-pupil-list__link {
  @include avatar-list.list__link;
}

.school-pupil-list__pupil-avatar {
  @include avatar-list.list__avatar;
}

.school-pupil-list__pupil-name {
  @include avatar-list.list__name;
}
