@use "../variables" as *;

.hint-balloon {
  animation: hovering-dragon 10s infinite;
}

.hint-balloon.fixed {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 1rem;
  transition: top 1.5s ease-in;
}

.hint-balloon__content {
  display: flex;
  position: relative;
  bottom: 2rem;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  max-width: 25rem;
  min-height: 2.5rem;
  margin: 1.25rem;
  padding: 1.25rem;
  transition: opacity 1s ease-in-out;
  transition-delay: 1s;
  border: 1px solid $c-gray-light;
  border-radius: 1.875rem;
  background-color: $c-gray-light;
  text-align: center;
}

.hint-balloon.hidden {
  display: none;
}

.hint-balloon.hidden .hint-balloon__content {
  opacity: 0;
}

.hint-balloon.visible .hint-balloon__content {
  opacity: 1;
}

.hint-balloon__content::before,
.hint-balloon__content::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  background-color: $c-gray-light;
}

.hint-balloon__content::before {
  top: -0.75rem;
  left: 1.75rem;
  width: 2.75rem;
  height: 2.75rem;
  box-shadow: -3.125rem 1.875rem 0 -0.75rem $c-gray-light;
}

.hint-balloon__content::after {
  bottom: -1.625rem;
  left: 1.625rem;
  width: 1.875rem;
  height: 1.875rem;
  box-shadow:
    0.5rem -2.125rem 0 0 $c-gray-light,
    -1.5rem 1.0625rem 0 -0.375rem $c-gray-light,
    -0.3125rem 2.5625rem 0 -0.525rem $c-gray-light;
}

.hint-balloon__content-text {
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 1rem;
}

.hint-balloon__image {
  position: relative;
  right: 0;
  width: 15rem;
  height: 10rem;
}

.hint-balloon__content .hint-balloon__password-display {
  grid-template-columns: repeat(4, 3rem);
  gap: 1rem;
  margin-top: 0.5rem;
}

.hint-balloon__password-display .password-display__value-part {
  width: 3rem;
  height: 3rem;
}

@keyframes hovering-dragon {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1rem);
  }

  100% {
    transform: translateY(0);
  }
}
