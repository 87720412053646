@use "../variables" as *;
@use "../utils" as *;

.pupil-layout {
  @include pupil-font;

  min-height: 100vh;
  max-height: 100vh;
  background-image: url("./pupil_background.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100rem;
}

.pupil-layout__header {
  display: flex;
  justify-content: space-between;
  gap: $margin-16;
}

.pupil-layout__switch-button-list {
  display: flex;
  gap: $margin-16;
}

.pupil-layout__switch-button {
  color: $c-black;
}

.pupil-layout.pupil-layout--horizontal-background {
  background-image: url("./pupil_background_horizontal.svg");
}

.pupil-layout__main-container {
  max-width: 92rem;
  margin: 0 auto;
  padding: $margin-32 $margin-80 $margin-48;
}

.pupil-layout__home-button-container {
  position: fixed;
  z-index: 10;
  bottom: $margin-48;
  margin-top: auto;
  padding-top: $margin-32;
}

.pupil-layout__home-button .round-button {
  box-shadow: $drop-shadow;
}
