@use "../../common/variables" as *;

.dynamic-table { // To override bootstrap
  .dynamic-table__head {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background-color: $c-white;
    box-shadow: $drop-shadow;
  }

  .dynamic-table__divider {
    // We cannot use margin, padding or transparent borders (different behavior on
    // Firefox vs Chrome) to create this empty space. So we create it with a div.
    visibility: hidden;
    height: $margin-6;
    border: 0;
  }

  .dynamic-table__body {
    border: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: $c-white;
    box-shadow: $drop-shadow;

    tr {
      border-bottom: solid 0.125rem $c-border;

      &:last-child {
        border-bottom: 0;
      }
    }

    .dynamic-table__row[tabindex="0"] {
      transition: color 0.3s ease;
      cursor: pointer;

      &:hover,
      &:focus-visible {
        color: $c-blue;
      }
    }
  }

  &.dynamic-table--simplified {
    .dynamic-table__divider {
      display: none;
    }

    .dynamic-table__head,
    .dynamic-table__body {
      box-shadow: none;
    }

    .dynamic-table__head {
      border-bottom: solid 0.125rem $c-border;
    }
  }

  td {
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
  }

  th,
  td {
    padding: 0.75rem;
    border: none;

    &:first-child {
      padding-left: $margin-20;
    }

    &:last-child {
      padding-right: $margin-20;
    }
  }
}

.head-item-sort__title,
.dynamic-table__head-item {
  font-weight: normal;

  .dynamic-table--simplified & {
    font-weight: bold;
  }
}

.dynamic-table__head-item-sort-button {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  gap: $margin-6;
}

.header-item-sort__icon {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $c-gray;
  font-size: 0.5rem;

  .icon:first-child {
    margin-bottom: -0.3rem;
  }

  svg {
    stroke-width: 0.2rem;
  }
}

.header-item-sort__icon--desc .icon:first-child,
.header-item-sort__icon--asc .icon:last-child {
  color: $c-black;
}

.dynamic-table__xlsx-download {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
}
