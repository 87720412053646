@use "../../../../common/variables" as *;
@use "../../../../common/utils";

.hidden-objects-game-display {
  width: 100%;
}

.hidden-objects-game-display__container {
  display: inline-block;
  position: relative;
}

.hidden-objects-game-display__full-image {
  display: block;
  max-width: 100%;
  max-height: 80vh;
}

.hidden-objects-game-display__full-image--placeholder {
  aspect-ratio: 1.46/1; // About the aspect ratio of the real image
  height: 100vh;
  background-color: $c-white;
}

.hidden-objects-game-display__tiles {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-flow: column wrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 2.6% 2.4% 1.5%; // Bottom padding includes some extra leeway to compensate for differences between browsers
  list-style: none;
  pointer-events: none; // Allow clicking trough to the image
}

.hidden-objects-game-display__tile {
  width: 5.748%;
  aspect-ratio: 1 / 1;
  margin-bottom: 0.7%;
  backdrop-filter: blur(5px);
  border-radius: 18%;
  pointer-events: all; // Allow clicking tiles inside click trough tile container
}

.hidden-objects-game-display__tile-button {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
  border: solid 1px black;
  border-radius: inherit;
  box-shadow: 3px 3px 5px -1px rgb(0 0 0 / 75%);

  .hidden-objects-game-display__tiles--selected-mode & {
    opacity: 0.3;
  }

  &:hover,
  &:focus-visible,
  .hidden-objects-game-display__tile--selected & {
    opacity: 1;
  }

  .hidden-objects-game-display__tile-valid-check {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $c-white;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($c-black, 0.8);
    }

    svg {
      position: relative;
      width: 45%;
      height: 45%;
    }
  }
}

.hidden-objects-game-display__tile-image {
  width: 100%;
  height: 100%;
}

.hidden-objects-game-display__hitbox {
  position: absolute; // Position set via attributes
}

.hidden-objects-game-display__hitbox--edit {
  outline: 0.125rem solid $c-black;
  background-color: rgba($c-white, 0.5);

  &.hidden-objects-game-display__hitbox--focus {
    z-index: 1;
    outline-color: $c-blue-dark;
    background-color: rgba($c-blue-light, 0.5);
  }
}

.hidden-objects-game-display__hitbox-handles {
  display: flex;
  position: relative;
  top: 100%;
  left: 100%;
  flex-wrap: wrap;
  width: 4rem;
  gap: 0.2rem;
}

.hidden-objects-game-display__hitbox-handle {
  margin: 0;
  padding: 0.2319rem;
  border: 0;
  border-radius: 0.1875rem;
  background-color: $c-white;
  font-size: 0.835rem;
}

.hidden-objects-game-display__hitbox-handle--delete {
  background-color: $c-red;
  color: $c-white;

  &:hover,
  &:focus-visible {
    filter: brightness(110%);
  }
}

button:not(:disabled) {
  &.hidden-objects-game-display__hitbox-handle--size {
    cursor: move;
  }

  &.hidden-objects-game-display__hitbox-handle--roundness {
    cursor: ns-resize;
  }
}

.hidden-objects-game-display__hitbox-handle-label {
  @include utils.visually-hidden;
}

.hidden-objects-game-display__controls {
  display: flex;
  align-items: center;
  margin-bottom: $margin-32;
  gap: $margin-16;
}

.hidden-objects-game-display__tip {
  font-size: $fs-large;
}
