@use "../../../common/variables" as *;
@use "../../../common/utils" as *;

.vocabulary-layout {
  @include pupil-font;

  display: flex;
  flex-direction: column;
  max-width: 92rem;
  min-height: 100vh;
  margin: 0 auto;
  padding: $margin-28 $margin-80 $margin-48;
}

.vocabulary-layout__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $margin-48;
  margin-bottom: $margin-28;
  gap: $margin-40;
}

.vocabulary-layout__title {
  display: flex;
  align-items: center;
  gap: $margin-24;
}

.vocabulary-layout__title-image {
  width: 2.5rem;
}

.vocabulary-layout__title-text {
  margin: 0;
  font-size: $fs-xlarge;
}
