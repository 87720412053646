@use "./../variables" as *;

.btn {
  color: $c-white;

  &.btn-primary {
    border-color: $c-green;
    background: $c-green;

    &:hover {
      border-color: $c-green-dark;
      background: $c-green-dark;
    }
  }

  &.btn-warning {
    border-color: $c-red;
    background: $c-red;

    &:hover {
      border-color: $c-red-dark;
      background: $c-red-dark;
    }
  }

  &.dropdown-toggle {
    &::after {
      content: unset;
    }
  }
}
